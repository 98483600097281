import React, { ReactNode } from "react";

export const ItemCard: React.FC<{
	text: ReactNode;
	img?: ReactNode;
	bg?: string;
}> = ({ img, text, bg }) => {
	// const options = {
	// 	whileInView: { scale: 1 },
	// 	viewport: { once: true },
	// 	initial: { scale: 1.5 },
	// 	transition: { duration: 2, ease: "easeOut" },
	// };
	return (
		<div className="sm:h-[45rem] lg:h-[26rem] h-[30rem] ">
			<div
				className={` ${bg} flex lg:flex-row flex-col items-end  justify-center min-w-[80vw] rounded-[30px] h-full relative overflow-hidden`}>
				<div className="lg:w-1/2 absolute sm:left-0 top-0 lg:p-16 sm:px-20 p-8 flex flex-col lg:items-center lg:h-full lg:justify-center">
					{text}
				</div>

				<div className="absolute sm:-right-6 md:right-0 flex w-full justify-end bottom-0">
					{img}
				</div>
			</div>
		</div>
	);
};
