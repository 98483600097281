import * as React from "react";

const SvgLinkedIn = (props: any) => (
	<svg
		width={17}
		height={16}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M3.742 5.766H.107v9.994h3.635V5.766ZM1.925 3.949a1.818 1.818 0 1 0 0-3.635 1.818 1.818 0 0 0 0 3.635ZM12.374 5.083a4.064 4.064 0 0 0-3.18 1.548v-.866H5.56v9.994h3.634v-6.36a1.818 1.818 0 0 1 3.634 0v6.36h3.634V9.172a4.089 4.089 0 0 0-4.088-4.089Z"
			fill="#C4C4C4"
		/>
	</svg>
);

export default SvgLinkedIn;
