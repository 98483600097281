import logo from "../assets/images/logo.png";
import logoBw from "../assets/images/logo-bw.png";

export function Logo() {
	return (
		<button className="md:w-[10.3rem] w-[6.4rem]">
			<img src={logo} alt="logo" loading="lazy" />
		</button>
	);
}

export function BwLogo() {
	return (
		<button className="md:w-[10.3rem] w-[6.4rem] lg:mx-0 mx-auto">
			<img src={logoBw} alt="logo" loading="lazy" />
		</button>
	);
}
