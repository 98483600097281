import React, { ReactNode } from "react";

export const Button: React.FC<{
	children: ReactNode;
	onClick?: () => void;
	className?: string;
	disabled?: boolean;
	outline?: boolean;
}> = ({ children, className, onClick, disabled, outline }) => {
	return (
		<button
			onClick={onClick}
			className={`md:px-12 px-3 rounded-full font-sans lg:text-base text-xs font-bold flex items-center border-2 border-lightGreen justify-center ${
				outline
					? "lg:py-[10px] py-[6px] text-lightGreen"
					: "bg-lightGreen lg:py-3 py-2 text-white"
			} ${
				disabled
					? "bg-opacity-30 hover:bg-opacity-30 cursor-not-allowed"
					: "hover:bg-opacity-70 "
			} ${className}`}>
			{children}
		</button>
	);
};
