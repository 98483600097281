import React, { ReactNode } from "react";

const Description: React.FC<{
	children: ReactNode;
	className?: string;
}> = ({ children, className }) => {
	return (
		<p
			className={`font-sans-lt lg:text-lg md:text-base text-sm text-neutral-100 ${className}`}>
			{children}
		</p>
	);
};

export default Description;
