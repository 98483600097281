import { useEffect, useState } from "react";

export interface LeaderBoardDataValues {
	count?: string;
	firstName?: string;
	id?: string;
	lastName?: string;
	url?: string | null;
	userName?: string;
}

// const url = `https://integrations.bezomoney.com/api/referAndEarn/leaderboard`;
const url = "https://integrations.bezomoney.com/api/savathon/leaderboard";

export const useLeaderBoardData = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [data, setData] = useState<LeaderBoardDataValues[]>([]);

	useEffect(function () {
		async function getLeaderBoardData() {
			setLoading(true);

			try {
				const res = await fetch(url);

				const data = await res.json();

				setData(data);
				setLoading(false);

				// return data;
			} catch (error) {
				setError("Data failed to load. Please check back later");
				setLoading(false);
			}
		}

		async function refetch() {
			try {
				const res = await fetch(url);

				const data = await res.json();

				setData(data);
			} catch (error) {
				setError("Data failed to load. Please check back later.");
			}
		}

		setInterval(() => {
			refetch();
		}, 10000);

		getLeaderBoardData();
	}, []);

	return { data, loading, error };
};
