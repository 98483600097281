import { Home } from "./pages/home";
import "slick-carousel/slick/slick.css";
import "react-responsive-modal/styles.css";
import "slick-carousel/slick/slick-theme.css";
import { Route, Routes } from "react-router-dom";
import TermsAndConditions from "./pages/terms";
import { LeaderBoard } from "./pages/leaderboard";

function App() {
	return (
		<Routes>
			<Route path="/" Component={Home} />
			<Route path="/terms" Component={TermsAndConditions} />
			<Route path="/leaderboard" Component={LeaderBoard} />
		</Routes>
	);
}

export default App;
