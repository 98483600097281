import NavBar from "../components/nav-bar";
import "react-responsive-modal/styles.css";
import useScroll from "../hooks/use-scroll";
import { Spacer } from "../components/spacer";
import Description from "../components/UI/description";
import { AppContextProvider } from "../helpers/context";
import { Carousel } from "../components/carousel/carousel";
import { IntroSection } from "../components/page-sections/intro";
import { TestedSection } from "../components/page-sections/tested";
import { SecuritySection } from "../components/page-sections/security";
import { Features1Section } from "../components/page-sections/features1";
import { Features2Section } from "../components/page-sections/features2";
import { GoalTypeSection } from "../components/page-sections/goal-types";

export function Home() {
	useScroll();

	return (
		<AppContextProvider>
			<div className="App bg-lightPink">
				<div className="lg:w-[85%] w-[90vw] mx-auto">
					<NavBar />
					<IntroSection />
				</div>
			</div>

			<div className="lg:w-5/6 w-[85vw] mx-auto">
				<Spacer className="md:h-32 h-20" />

				<div className="font-sans flex flex-col space-y-5 text-center items-center justify-center">
					<h2 className="lg:text-6xl md:text-5xl font-recoleta text-4xl lg:w-1/2 md:w-4/5 w-full">
						Save and Send money with Bezo.
					</h2>
					<Description className="text-center lg:w-3/5 md:w-4/5">
						Do more with your money using{" "}
						<span className="font-inter">Bezo</span>, which allows you to make{" "}
						<span className="font-inter">SMART</span> savings and sending moves.
					</Description>
				</div>

				<Spacer className="md:h-32 h-20" />

				<Features1Section />

				<Spacer className="md:h-32 h-20" />

				<Features2Section />

				<Spacer className="lg:h-44 md:h-32 h-20" />

				<div>
					<Carousel />
				</div>

				<Spacer className="lg:h-44 md:h-32 h-20" />

				<GoalTypeSection />

				<Spacer className="md:h-52 h-20" />

				<SecuritySection />

				<Spacer className="md:h-32 h-20" />
			</div>
			<TestedSection />
		</AppContextProvider>
	);
}
