import { Link } from "react-router-dom";
import { Icons } from "./icons";
import { BwLogo } from "./logo";
import { Spacer } from "./spacer";

export const Footer = () => {
	const year = new Date().getFullYear();
	// const navigate = useNavigate();

	return (
		<>
			<div className="py-20 bg-neutral-400">
				<div className="flex flex-col items-center space-y-4 md:w-5/6 mx-auto">
					<div className="text-white md:flex flex-col space-y-3 hidden w-full font-sans-lt text-sm">
						<Link to="/">
							<BwLogo />
						</Link>
						<div className="flex items-center">
							{" "}
							<p>+233 59 969 9469 </p> <span className="mx-2">|</span>
							<p>+233 59 424 4780 </p> <span className="mx-2">|</span>
							<p>+233 20 512 0095 </p> <span className="mx-2">|</span>
							<a
								href="mailto: hello@mybezoapp.com"
								className="text-base underline">
								hi@mybezoapp.com
							</a>
						</div>
					</div>
					<div className="flex flex-col space-y-3 text-white md:hidden font-sans-lt text-sm items-center">
						<Link to="/">
							<BwLogo />
						</Link>
						<div className="flex flex-col space-y-1 justify-center items-center">
							<p>+233 59 969 9469 </p>
							<p>+233 59 424 4780 </p>
							<p>+233 20 512 0095 </p>
							<a
								href="mailto: hello@mybezoapp.com"
								className="text-base underline">
								hi@mybezoapp.com
							</a>
						</div>
					</div>

					<div className="w-full bg-neutral-50 h-[2px]"></div>

					<Spacer className="h-5" />

					<div className="md:flex hidden items-center w-full justify-between font-roboto text-neutral-50 text-xs">
						<p>©Bezo{year}.</p>

						{/* <div className="flex flex-col">
							<div className="flex items-center space-x-2">
								<button>Privacy Policy</button> <span>|</span>
								<Link to="/terms">Terms & Conditions</Link>
							</div>
						</div> */}

						<Icons />
					</div>

					<div className="flex flex-col md:hidden  space-y-2 items-center w-full justify-center font-roboto text-neutral-50 text-xs">
						<div className="flex flex-col">
							{/* <div className="flex items-center space-x-2">
								<button>Privacy Policy</button> <span>|</span>
								<Link to="/terms">Terms & Conditions</Link>
							</div> */}
						</div>

						<Icons />

						<p>©Bezo{year}.</p>
					</div>
				</div>
			</div>

			<Spacer className="h-16" />

			{/* Legal Section */}

			<div className="w-5/6 mx-auto">
				<p className=" font-sans-lt text-xs text-neutral-100">
					<span>
						<span className="font-sans-md">Bezo</span> is a financial platform -
						operated by BezoMoney Technologies Limited - not a bank. Banking
						services are powered by Bezo Susu Enterprise, a member of the Ghana
						Co-operative Susu Collectors Association (GCSCA).
					</span>
					<Spacer className="h-2" />
					<span>
						{" "}
						Savings accounts are held by Bezo Susu Enterprise. Standard data
						charges may apply when downloading the app.
					</span>
				</p>
			</div>

			<Spacer className="h-16" />
		</>
	);
};
