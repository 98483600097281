import NavBar from "../components/nav-bar";
import { Spacer } from "../components/spacer";
import clock from "../assets/images/clock.png";
import { Countdown } from "../components/countdown";
import { LeaderBoardData } from "../components/leaderboard-data";
import { ReactNode } from "react";

export const LeaderBoard = () => {
	const NO_COUNTDOWN = true;

	function Bold({ children }: { children: ReactNode }) {
		return <span className="font-sans mb-2 sm:text-lg">{children}</span>;
	}
	return (
		<div className="bg-lightPink leaderboard">
			<div className=" lg:w-[85%] w-[90vw] mx-auto ">
				<NavBar />
			</div>

			<Spacer className="h-6" />

			{NO_COUNTDOWN ? (
				<div className="h-[50vh] flex items-center justify-center">
					<div className="flex flex-col items-center justify-center">
						<img src={clock} alt="" className="sm:w-52 w-48" />
						<p className="mt-6">NO CAMPAIGN RUNNING NOW!!!</p>
						<p className="text-center mt-2 font-sans-md text-sm w-full text-neutral-400 text-opacity-60">
							You shall be notified when next a campaign is run.
						</p>
					</div>
				</div>
			) : (
				<>
					<div className="lg:w-1/2 w-3/4 flex flex-col  justify-center items-center mx-auto">
						<Bold>10 to Thousand Savings Challenge</Bold>
						<span className="font-sans-lt mb-2 sm:text-lg text-center">
							We’re giving you a thousand reasons to end 2023 with a bang in our{" "}
							<Bold>10 to Thousand Savings Challenge.</Bold>
						</span>

						<span className="font-sans-lt mb-2 sm:text-lg text-center">
							Save at least <Bold>GHS10</Bold> every day for 100 days and crush
							your financial goals before 2024. Join <Bold>40,000+</Bold> savers
							who are committed to saving at least <Bold>GHS1,000</Bold> by the
							end of 2023.
						</span>
						<p className="font-sans-lt mb-2 sm:text-lg text-center">
							You’ve got this! Let’s go!{" "}
						</p>
						{/* <span className="font-sans-lt mb-2 sm:text-lg text-center">
							Challenge starts on{" "}
							<span className="font-sans">July 17, 2023 </span> and ends on{" "}
							<span className="font-sans">July 31, 2023 </span>.
						</span>  */}

						<Spacer className="h-5" />
						<Countdown />
					</div>

					<Spacer className="md:h-5 h-3" />

					<LeaderBoardData />

					<Spacer className="md:h-6 h-4" />

					<div className="lg:w-1/2 w-3/4 flex flex-col  justify-center items-center mx-auto">
						{/* <span className="font-sans-lt mb-2 sm:text-lg text-center">
							The record holder wins{" "}
							<span className="font-sans">GHS2,000 </span>while other impressive
							savers win <span className="font-sans-md">promo codes</span>,{" "}
							<span className="font-sans-md">gift vouchers</span> and more.
						</span> */}
					</div>
				</>
			)}
			<Spacer className="md:h-24 h-16" />
		</div>
	);
};
