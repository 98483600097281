import React, { ReactNode } from "react";

const Heading: React.FC<{ children: ReactNode; className?: string }> = ({
	children,
	className,
}) => {
	return (
		<p className={`font-recoleta font-bold text-neutral-300 ${className}`}>
			{children}
		</p>
	);
};

export const Heading2: React.FC<{
	children: ReactNode;
	className?: string;
}> = ({ children, className }) => {
	return (
		<p
			className={`font-sans md:text-3xl text-lg font-bold text-neutral-300 ${className}`}>
			{children}
		</p>
	);
};

export default Heading;
