import { Spacer } from "../spacer";
import { ItemCard } from "./card";
import Description from "../UI/description";
import { motion } from "framer-motion";
import manbg from "../../assets/images/man-bg.webp";

export const Item1 = () => {
	// const options = {
	// 	whileInView: { scale: 1 },
	// 	viewport: { once: true },
	// 	initial: { scale: 1.5 },
	// 	transition: { duration: 2, ease: "easeOut" },
	// };

	return (
		<ItemCard
			bg="bg-pink"
			text={
				<>
					<h2 className="md:text-5xl text-3xl font-recoleta text-left">
						No transaction fees. No surprises.
					</h2>
					<Spacer className="lg:h-6 h-4" />
					<Description className="text-neutral-400 lg:text-lg md:text-lg text-base">
						All transactions on <span className="font-inter">Bezo</span> are
						completely free! Level up with our monthly subscription plans to
						unlock other features and enjoy unlimited services on our app.
					</Description>
					{/* <Spacer className="md:h-8 h-6" />
					<p className="text-left md:text-sm text-[10px] w-full italic text-neutral-300 text-opacity-50 font-sans-md">
						Starting at GHS10/month after your 14-day free trial. Cancel
						anytime.
					</p> */}
				</>
			}
			img={
				<div className="lg:w-1/2 flex justify-center items-center  md:-mr-12 -mr-4 overflow-hidden lg:mr-0 rounded-3xl">
					<motion.img src={manbg} alt="" />
				</div>
			}
		/>
	);
};
