import { Spacer } from "../spacer";
import { ItemCard } from "./card";
import { motion } from "framer-motion";
import Description from "../UI/description";
import lady from "../../assets/images/carousel-lady.webp";

export const Item2 = () => {
	// const options = {
	// 	whileInView: { scale: 1 },
	// 	viewport: { once: true },
	// 	initial: { scale: 1.5 },
	// 	transition: { duration: 2, ease: "easeOut" },
	// };
	return (
		<ItemCard
			bg="bg-lightBlue"
			text={
				<>
					<h2 className="md:text-5xl text-3xl font-recoleta texxl text-left">
						Be the boss of your money.
					</h2>
					<Spacer className="lg:h-6 h-4" />
					<Description className="text-neutral-400 lg:text-lg md:text-lg text-base">
						Be in total control of your money, anytime and anywhere. Monitor
						your account and perform transactions from the comfort of your home,
						office or on the go.
					</Description>
				</>
			}
			img={
				<div className="lg:w-1/2 w-4/5 mx-auto flex justify-center items-center overflow-hidden lg:mr-0 rounded-3xl">
					<motion.img src={lady} alt="" />
				</div>
			}
		/>
	);
};
