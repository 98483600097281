import {
	createContext,
	PropsWithChildren,
	ReactNode,
	useContext,
	useState,
} from "react";

const AppCtxt = createContext({
	setInputActive(active: boolean) {},
	inputActive: false,
});

export function AppContextProvider({
	children,
}: PropsWithChildren<{ children: ReactNode }>) {
	const [inputActive, setInputActive] = useState(false);

	return (
		<AppCtxt.Provider value={{ inputActive, setInputActive }}>
			{children}
		</AppCtxt.Provider>
	);
}

export const useAppContext = () => useContext(AppCtxt);
