import { Logo } from "./logo";
import { Button } from "./UI/button";
import { motion } from "framer-motion";
import { useAppContext } from "../helpers/context";
import { Link } from "react-router-dom";

const NavBar = () => {
	const { setInputActive } = useAppContext();
	return (
		<motion.nav
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{
				type: "tween",
				duration: 1.5,
			}}
			className="flex items-center justify-between py-8 mx-auto">
			<Link to="/">
				<Logo />
			</Link>

			<div className="md:w-1/2 w-[50%]  lg:space-x-4 space-x-4 sm:w-1/2 flex items-center justify-end">
				<Link
					to="/leaderboard"
					className="font-sans md:text-lg text-xs text-neutral-300 text-center">
					<Button outline onClick={() => setInputActive(true)}>
						Leaderboard
					</Button>
				</Link>
				<a
					href="https://app.bezosusu.com/auth/login"
					target="_blank"
					rel="noreferrer"
					className="font-sans md:text-lg text-xs text-neutral-300 text-center">
					<Button onClick={() => setInputActive(true)}>Login</Button>
				</a>

				{/* <a
					href="https://app.bezosusu.com/auth/register"
					target="_blank"
					rel="noreferrer"
					className="font-sans md:text-lg text-xs text-neutral-300 text-center">
					<Button onClick={() => setInputActive(true)}>Get Started</Button>
				</a> */}
				{/* <a href="#email" rel="noreferrer">
					<Button onClick={() => setInputActive(true)}>
						Join the waitlist
					</Button>
				</a> */}
			</div>
		</motion.nav>
	);
};

export default NavBar;
