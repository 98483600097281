import { Spacer } from "../spacer";
import { motion } from "framer-motion";
import Description from "../UI/description";
import lockType from "../../assets/images/lock-type.png";

export const GoalTypeSection = () => {
	return (
		<section className="flex flex-col items-center">
			<h2 className="text-center font-recoleta lg:text-6xl md:text-5xl text-4xl">
				Lock or Flex ? You decide! 
			</h2>

			<Spacer className="h-5" />

			<Description className="text-center lg:w-1/2 md:w-2/3">
				Whether you’re saving towards short- or long-term financial goals, you
				can keep your goals open or locked with{" "}
				<span className="font-bold font-sans">BezoFlex</span> or{" "}
				<span className="font-bold font-sans">BezoLock</span>. 
			</Description>

			<Spacer className="h-16" />

			<motion.div
				initial={{ scale: 0.7, opacity: 0 }}
				viewport={{ once: true }}
				whileInView={{ scale: 1, opacity: 1 }}
				transition={{ duration: 1 }}
				className="lg:w-[80%] w-full">
				<img src={lockType} alt="" loading="lazy" className="rounded-3xl" />
			</motion.div>
		</section>
	);
};
