import * as React from "react";

const SvgTwitter = (props: any) => (
	<svg
		width={18}
		height={15}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M17.978 2.035a6.856 6.856 0 0 1-2.034.577 3.657 3.657 0 0 0 1.557-2.03 6.942 6.942 0 0 1-2.249.89A3.482 3.482 0 0 0 12.667.315c-1.955 0-3.54 1.643-3.54 3.67 0 .287.03.567.09.836-2.942-.153-5.552-1.614-7.3-3.835a3.754 3.754 0 0 0-.478 1.846c0 1.273.625 2.396 1.575 3.054a3.437 3.437 0 0 1-1.604-.46v.047c0 1.778 1.22 3.261 2.84 3.599a3.458 3.458 0 0 1-1.599.062c.45 1.458 1.759 2.52 3.308 2.549a6.948 6.948 0 0 1-5.243 1.52 9.77 9.77 0 0 0 5.429 1.649c6.514 0 10.076-5.593 10.076-10.442 0-.16-.003-.318-.01-.476a7.31 7.31 0 0 0 1.767-1.898Z"
			fill="#C4C4C4"
		/>
	</svg>
);

export default SvgTwitter;
