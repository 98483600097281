import * as React from "react";

const SvgFacebook = (props: any) => (
	<svg
		width={8}
		height={16}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M7.94 5.315H5.465V3.712c0-.603.404-.743.688-.743H7.9V.323L5.494.313c-2.67 0-3.278 1.975-3.278 3.238v1.764H.672v2.727h1.544v7.717h3.249V8.042h2.192l.283-2.727Z"
			fill="#C4C4C4"
		/>
	</svg>
);

export default SvgFacebook;
