import { Spacer } from "../spacer";
import { motion } from "framer-motion";
import ios from "../../assets/images/apple.png";
import bg from "../../assets/images/tested/bg.png";
import { SectionCards } from "../UI/section-cards";
import girl from "../../assets/images/tested/girl.png";
import line from "../../assets/images/tested/line.png";
import text from "../../assets/images/tested/text.png";
import android from "../../assets/images/android.png";

export const TestedSection = () => {
	return (
		<div className="bg-lightBlue">
			<Spacer className="md:h-28 h-20" />

			<div className="lg:w-5/6 w-[80vw] mx-auto rounded-2xl">
				<SectionCards
					image={
						<>
							<motion.img
								src={girl}
								initial={{ opacity: 0.5, scale: 1.1 }}
								whileInView={{ opacity: 1, scale: 1 }}
								transition={{ duration: 1 }}
								viewport={{ once: true }}
								alt="section-img"
								className="absolute z-30"
							/>
							<motion.img
								src={text}
								initial={{ x: "20%", opacity: 0 }}
								whileInView={{ x: 0, opacity: 1 }}
								transition={{ type: "spring", bounce: 0.6, delay: 0.8 }}
								viewport={{ once: true }}
								alt="section-img"
								className="absolute z-40 right-0 md:w-[18rem] w-[10rem] top-[20%]"
							/>
							<motion.div
								className="absolute z-10"
								initial={{ opacity: 0 }}
								whileInView={{ opacity: 1 }}
								transition={{ delay: 1 }}
								viewport={{ once: true }}>
								<img src={line} alt="section-img" loading="lazy" />
							</motion.div>
							<motion.img
								src={bg}
								alt="section-img"
								className="absolute rounded-2xl"
							/>
						</>
					}
					reverse
					text={
						<>
							<h2 className="text-left font-recoleta md:text-6xl text-5xl">
								Tried, Tested & Approved.  
							</h2>

							<Spacer className="h-3" />

							<ul className="font-sans-lt lg:text-lg md:text-base text-sm text-neutral-100">
								<li>
									{" "}
									Join our community of{" "}
									<span className="font-sans text-2xl text-neutral-300">
										46,000+
									</span>{" "}
									users who are crushing their financial goals with{" "}
									<span className="font-sans-md">Bezo</span>!
								</li>
							</ul>

							<Spacer className="h-6" />

							{/* <Email className="lg:w-full md:w-3/4" /> */}

							<div className="flex items-center space-x-2">
								<a
									href="https://play.google.com/store/apps/details?id=com.bezosusu.app"
									target="_blank"
									rel="noreferrer">
									<img placeholder="blur" src={android} alt="" />
								</a>

								<a
									href="https://apps.apple.com/gh/app/bezo/id6447621019"
									target="_blank"
									rel="noreferrer">
									<img placeholder="blur" src={ios} alt="" />
								</a>
							</div>
						</>
					}
				/>
			</div>

			<Spacer className="md:h-28 h-20" />
		</div>
	);
};
