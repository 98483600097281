import { Spacer } from "../spacer";
import Heading from "../UI/heading";
import { motion } from "framer-motion";
import Description from "../UI/description";
import phones from "../../assets/images/phone.png";
import piece2 from "../../assets/images/pieces1.png";
import piece1 from "../../assets/images/pieces2.png";
import piece3 from "../../assets/images/pieces3.png";
import piece4 from "../../assets/images/pieces4.png";
import piece5 from "../../assets/images/pieces5.png";
import piece6 from "../../assets/images/envelope.png";
import android from "../../assets/images/android.png";
import ios from "../../assets/images/apple.png";
// import SvgPhones from "../../assets/svgs/phones";

const containerVariants = {
	hidden: {
		opacity: 0,
		y: 100,
	},
	visible: {
		opacity: 1,
		y: 0,
		transition: {
			duration: 1,
			staggerChildren: 0.5,
		},
	},
};

const pieceContainerVariants = {
	hidden: {
		opacity: 0,
		zIndex: -2,
	},
	visible: {
		opacity: 1,
		zIndex: 0,
		transition: {
			delay: 2,
			duration: 1,
		},
	},
};

const pieceOrigin = { left: "50%", top: "40%", opacity: 0, zIndex: 0 };

const options = {
	transition: { delay: 2, duration: 1 },
	opacity: 1,
	zIndex: 10,
};
const piece1Variant = {
	hidden: pieceOrigin,
	visible: { left: "10%", top: 0, ...options },
};
const piece2Variant = {
	hidden: pieceOrigin,
	visible: { left: 0, top: "40%", ...options },
};
const piece3Variant = {
	hidden: pieceOrigin,
	visible: { top: "95%", left: "40%", ...options },
};
const piece4Variant = {
	hidden: pieceOrigin,
	visible: { left: "90%", top: "60%", ...options },
};
const piece5Variant = {
	hidden: pieceOrigin,
	visible: { left: "95%", top: "40%", ...options },
};
const piece6Variant = {
	hidden: pieceOrigin,
	visible: { left: "90%", top: 0, ...options },
};

const childVariants = {
	hidden: {
		x: -400,
		y: -400,
		opacity: 0,
	},
	visible: {
		x: 0,
		y: 0,
		opacity: 1,
		transition: {
			duration: 2.5,
		},
	},
};

export const IntroSection = () => {
	// const { setInputActive } = useAppContext();

	// function handleOutsideClick() {
	// 	setInputActive(false);
	// }

	// const emailRef = useOutsideClick(handleOutsideClick);

	return (
		<>
			<Spacer id="email" className="md:h-12 h-4" />

			<section className="mx-auto flex lg:flex-row flex-col items-center">
				<motion.div
					className="flex flex-col space-y-4 lg:w-1/2 w-full"
					variants={containerVariants}
					animate="visible"
					initial="hidden">
					<Heading className="lg:text-7xl md:text-6xl text-4xl">
						<motion.span variants={childVariants} className="">
							Save with ease
						</motion.span>
						<br />
						<motion.span variants={childVariants}>
							and send money for free!
						</motion.span>{" "}
						<br />
						{/* <motion.span variants={childVariants}> Free!</motion.span> */}
					</Heading>

					<Description className="md:w-[85%]">
						<motion.span variants={childVariants}>
							Try the all-in-one personal finance app that helps you manage your
							money and progress towards financial freedom with ease. 
						</motion.span>
					</Description>

					<Spacer className="md:h-4 h-2" />

					<div className="md:w-4/5">
						{/* <Email /> */}
						<div className="flex items-center space-x-2">
							<a
								href="https://play.google.com/store/apps/details?id=com.bezosusu.app"
								target="_blank"
								rel="noreferrer">
								<img placeholder="blur" src={android} alt="" />
							</a>
							<a
								href="https://apps.apple.com/gh/app/bezo/id6447621019"
								target="_blank"
								rel="noreferrer">
								<img placeholder="blur" src={ios} alt="" />
							</a>
						</div>
					</div>
					{/* 
					<p className="text-neutral-300 font-sans-lt md:text-xs text-[9px] md:w-[75%]">
						<span className="font-sans-md">Bezo</span> is a financial platform
						backed by <span className="font-sans-md">BezoSusu Enterprise</span>,
						a member of the Ghana Co-operative Susu Collectors Association
						(GCSCA).
					</p> */}
				</motion.div>

				<Spacer className="lg:h-0 md:h-16 h-8" />

				<motion.div
					initial={{ y: 30 }}
					animate={{
						y: 0,
					}}
					transition={{
						duration: 1,
						delay: 1.5,
						repeat: Infinity,
						repeatType: "reverse",
					}}
					className="lg:w-1/2 md:w-4/5 lg:h-[35rem] flex items-center justify-center relative">
					<img src={phones} alt="" loading="lazy" />
					{/* <SvgPhones /> */}

					<motion.div
						variants={pieceContainerVariants}
						animate="visible"
						initial="hidden"
						className="absolute md:w-[85%] w-[85%] right-10 mx-auto h-full">
						<motion.img
							src={piece1}
							alt=""
							width={98}
							height={68}
							className="absolute"
							variants={piece1Variant}
						/>
						<motion.img
							src={piece2}
							alt=""
							width={30}
							height={37}
							className="absolute"
							variants={piece2Variant}
						/>
						<motion.img
							src={piece3}
							alt=""
							width={43}
							height={47}
							className="absolute"
							variants={piece3Variant}
						/>
						<motion.img
							src={piece4}
							alt=""
							width={89}
							height={71}
							className="absolute"
							variants={piece4Variant}
						/>
						<motion.img
							src={piece5}
							alt=""
							width={34}
							height={31}
							className="absolute"
							variants={piece5Variant}
						/>
						<motion.img
							src={piece6}
							alt=""
							width={65}
							className="absolute"
							variants={piece6Variant}
						/>
					</motion.div>
				</motion.div>
			</section>

			<Spacer className="h-20" />
		</>
	);
};
