import React, { ReactNode } from "react";

export const SectionCards: React.FC<{
	image: ReactNode;
	text: string | ReactNode;
	reverse?: boolean;
	defaultDirectionStyles?: string;
	imageStyle?: string;
	textContainerStyle?: string;
}> = ({
	image,
	text,
	reverse,
	imageStyle,
	textContainerStyle,
	defaultDirectionStyles = "flex-col space-y-14",
}) => {
	return reverse ? (
		<div
			className={`flex lg:flex-row items-center lg:justify-between justify-center mx-auto ${defaultDirectionStyles}`}>
			<div className={`lg:w-[40%] w-full text-left ${textContainerStyle}`}>
				{text}
			</div>

			<div
				className={`flex items-center justify-center lg:w-1/2 w-full md:h-[25rem] h-[15rem] relative overflow-hidden rounded-3xl ${imageStyle}`}>
				{image}
			</div>
		</div>
	) : (
		<div className="flex  lg:flex-row flex-col lg:space-y-0 space-y-8 items-center lg:justify-between justify-center mx-auto">
			<div
				className={`flex items-center justify-center lg:w-1/2 w-full md:h-[25rem] h-[15rem] relative overflow-hidden rounded-3xl ${imageStyle}`}>
				{image}
			</div>
			<div className={`lg:w-[40%] w-full text-left ${textContainerStyle}`}>
				{text}
			</div>
		</div>
	);
};
