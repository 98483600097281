import { Footer } from "../footer";
import React, { ReactNode } from "react";
// import NavBar from "../nav-bar";

export const Layout: React.FC<{ children: ReactNode }> = ({ children }) => {
	return (
		<>
			{children}

			<Footer />
		</>
	);
};
