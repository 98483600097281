import { Spacer } from "../spacer";
import { ItemCard } from "./card";
import { motion } from "framer-motion";
import Description from "../UI/description";
import streakMan from "../../assets/images/carousel-streak.webp";

export const Item4 = () => {
	// const options = {
	// 	whileInView: { scale: 1 },
	// 	viewport: { once: true },
	// 	initial: { scale: 1.5 },
	// 	transition: { duration: 2, ease: "easeOut" },
	// };
	return (
		<ItemCard
			bg="bg-yellow"
			text={
				<>
					<h2 className="md:text-5xl text-3xl font-recoleta texxl text-left">
						Oh, my streaking goodness! 
					</h2>

					<Spacer className="lg:h-6 h-4" />

					<Description className="text-neutral-400 lg:text-lg md:text-lg text-base">
						Track your savings’ consistency with our streak feature. The dimmer
						your streak, the more you must save. The more lit your streaks are,
						the more progress you’re making. 
					</Description>
				</>
			}
			img={
				<div className="lg:w-1/2 flex justify-center items-center  md:-mr-12 -mr-4 overflow-hidden lg:mr-0 rounded-3xl">
					<motion.img src={streakMan} alt="" />
				</div>
			}
		/>
	);
};
