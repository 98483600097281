import * as React from "react";

const SvgInsta = (props: any) => (
	<svg
		width={16}
		height={16}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M14.623.314H1.108a.965.965 0 0 0-.965.965v13.515c0 .533.431.965.965.965h13.515a.965.965 0 0 0 .965-.965V1.279a.965.965 0 0 0-.965-.965ZM5.935 8.036s.152-1.93 1.93-1.93 1.93 1.93 1.93 1.93.057 1.931-1.93 1.931c-1.986 0-1.93-1.93-1.93-1.93Zm7.722 5.792H2.073V7.071h2.068c-.08.31-.137.63-.137.965a3.861 3.861 0 0 0 7.723 0c0-.335-.057-.655-.137-.965h2.067v6.757Zm0-8.688h-2.896V2.244h2.896V5.14Z"
			fill="#C4C4C4"
		/>
	</svg>
);

export default SvgInsta;
