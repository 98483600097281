import { Spacer } from "../spacer";
import { motion } from "framer-motion";
import { Heading2 } from "../UI/heading";
import Description from "../UI/description";
import girl from "../../assets/images/girl.png";
import { SectionCards } from "../UI/section-cards";

export const Features1Section = () => {
	return (
		<SectionCards
			image={
				<>
					<motion.img
						initial={{ x: -200, opacity: 0 }}
						whileInView={{ x: 0, opacity: 1 }}
						transition={{ duration: 1 }}
						viewport={{ once: true }}
						src={girl}
						alt="section-img"
						className="absolute z-40"
					/>
				</>
			}
			text={
				<>
					<Heading2>Reach your savings goals in a breeze. </Heading2>

					<Spacer className="md:h-3 h-2" />

					<Description>
						There’s never been a better way to set and meet your savings goals.{" "}
						<span className="font-inter">Bezo</span> allows you to create
						savings goals for literally anything or multiple things
						concurrently. 
					</Description>
				</>
			}
		/>
	);
};
