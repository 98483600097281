import { useLayoutEffect, useState } from "react";
import { Item1 } from "./item1";
import { Item2 } from "./item2";
import { Item3 } from "./item3";
import { Item4 } from "./item4";
import Slider from "react-slick";

const items = [Item1, Item2, Item3, Item4];

// const variants = {
// 	initial: (direction: number) => {
// 		return {
// 			x: direction > 0 ? 300 : -300,
// 			opacity: 0,
// 		};
// 	},

// 	animate: {
// 		x: 0,
// 		opacity: 1,
// 		transition: {
// 			duration: 0.5,
// 			ease: "easeInOut",
// 			opacity: {
// 				duration: 1,
// 			},
// 		},
// 	},
// };

export const Carousel = () => {
	const [activeItemIdx, setActiveItemIdx] = useState(0);

	// function onNext() {
	// 	setDirection(1);
	// 	if (activeItemIdx === items.length - 1) {
	// 		setActiveItemIdx(0);
	// 		return;
	// 	}

	// 	setActiveItemIdx(prev => prev + 1);
	// }

	useLayoutEffect(() => {
		if (activeItemIdx !== 0) {
			setTimeout(() => {
				setActiveItemIdx(0);
			}, 800);
		}
	}, [activeItemIdx]);

	// function onPrev() {
	// 	setDirection(-1);
	// 	if (activeItemIdx === 0) {
	// 		setActiveItemIdx(items.length - 1);
	// 		return;
	// 	}

	// 	setActiveItemIdx(activeItemIdx - 1);
	// }

	const settings = {
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		speed: 1000,
		autoplaySpeed: 5000,

		customPaging: (i: any) => {
			return (
				<div
					style={{
						width: "20px",
						height: "20px",
						borderRadius: "50%",
						backgroundColor: "white",
						marginTop: "-40px",
						opacity: 0.7,
						position: "absolute",
					}}></div>
			);
		},
	};

	return (
		// <div className="flex flex-col space-y-4 items-center relative">
		// 	<motion.div className="w-full overflow-hidden ">
		// 		<motion.div className="w-full flex ">
		// 			<AnimatePresence initial={false} custom={direction}>
		// 				<motion.div
		// 					key={activeItemIdx}
		// 					variants={variants}
		// 					initial={"initial"}
		// 					animate={"animate"}
		// 					custom={direction}>
		// 					{Item(activeItemIdx)}
		// 				</motion.div>
		// 			</AnimatePresence>
		// 		</motion.div>
		// 	</motion.div>
		// 	<div className="space-x-2 absolute bottom-4">
		// 		{" "}
		// 		{Array(items?.length)
		// 			.fill(null)
		// 			.map((_, idx) => {
		// 				return (
		// 					<button
		// 						key={idx}
		// 						onClick={() => {
		// 							setActiveItemIdx(prev => {
		// 								if (prev > idx) {
		// 									setDirection(-1);
		// 								} else {
		// 									setDirection(1);
		// 								}

		// 								return idx;
		// 							});
		// 						}}
		// 						className={`bg-white rounded-full w-6 h-6 ${
		// 							activeItemIdx !== idx ? "bg-opacity-50" : ""
		// 						}`}></button>
		// 				);
		// 			})}
		// 	</div>
		// </div>

		<div className="flex space-y-4 items-center relative">
			<div id="home" className="grid grid-cols-1 sm:z-auto">
				<Slider {...settings} className="w-full">
					{/* <Item1 />
					<Item2 />
					<Item3 />
					<Item4 /> */}
					{items.map((_, idx) => {
						const Item = items[idx];

						return <Item key={idx} />;
					})}
					{/* <motion.div
						key={activeItemIdx}
						variants={variants}
						initial={"initial"}
						animate={"animate"}
						custom={direction}>
						{Item(activeItemIdx)}
					</motion.div> */}
				</Slider>
			</div>

			{/* <div className="space-x-2 absolute left-0 right-0 mx-auto flex justify-center bottom-4">
				{" "}
				{Array(items?.length)
					.fill(null)
					.map((_, idx) => {
						return (
							<button
								key={idx}
								onClick={() => {
									setActiveItemIdx(prev => {
										if (prev > idx) {
											setDirection(-1);
										} else {
											setDirection(1);
										}

										return idx;
									});
								}}
								className={`bg-white rounded-full w-6 h-6 ${
									activeItemIdx !== idx ? "bg-opacity-50" : ""
								}`}></button>
						);
					})}
			</div> */}
		</div>
	);
};
