import { Spacer } from "../spacer";
import { motion } from "framer-motion";
import { SectionCards } from "../UI/section-cards";
import lockImg from "../../assets/images/lock.png";

export const SecuritySection = () => {
	const options = {
		whileInView: { scale: 1 },
		viewport: { once: true },
		initial: { scale: 1.5 },
		transition: { duration: 2, ease: "easeOut" },
	};

	return (
		<SectionCards
			image={<motion.img {...options} src={lockImg} alt="section-img" />}
			textContainerStyle="w-[45%]"
			text={
				<>
					<h2 className="text-left font-recoleta md:text-5xl text-4xl">
						100% protection & support guaranteed.
					</h2>

					<Spacer className="h-6" />

					<ul>
						<li>
							<p className="font-sans text-2xl">Security</p>
							<p className="font-sans-lt lg:text-base md:text-sm text-sm text-neutral-100">
								We’ve implemented rigorous security measures to ensure that your
								money and personal information are safe with us.
							</p>
						</li>

						<Spacer className="h-5" />

						<li>
							<p className="font-sans text-2xl">Protection</p>
							<p className="font-sans-lt lg:text-base md:text-sm text-sm text-neutral-100">
								Your funds are secured through the Ghana Deposit Protection
								Corporation.
							</p>
						</li>

						<Spacer className="h-5" />

						<li>
							<p className="font-sans text-2xl">Customer Support</p>
							<p className="font-sans-lt lg:text-base md:text-sm text-sm text-neutral-100">
								And if you ever need help, our incredible customer service team
								is more than ready to assist you!
							</p>
						</li>
					</ul>
				</>
			}
		/>
	);
};
