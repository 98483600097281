import { Spacer } from "../spacer";
import { motion } from "framer-motion";
import { Heading2 } from "../UI/heading";
import Description from "../UI/description";
import man from "../../assets/images/man.png";
import { SectionCards } from "../UI/section-cards";

export const Features2Section = () => {
	return (
		<SectionCards
			image={
				<motion.img
					initial={{ x: 200, opacity: 0 }}
					whileInView={{ x: 0, opacity: 1 }}
					transition={{ duration: 1 }}
					viewport={{ once: true }}
					src={man}
					alt="section-img"
				/>
			}
			reverse
			defaultDirectionStyles="flex-col-reverse"
			text={
				<>
					<Spacer className="lg:h-0 h-6" />

					<Heading2 className="">
						Transfers made simpler, easier and faster.
					</Heading2>

					<Spacer className="md:h-3 h-2" />

					<Description>
						Send money to your friends and loved ones from your{" "}
						<span className="font-inter">BezoWallet</span> with ease. Transfers
						can be conveniently made to other{" "}
						<span className="font-inter">BezoWallets</span>, MoMo, and bank
						accounts. 
					</Description>
				</>
			}
		/>
	);
};
