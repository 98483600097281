import SvgInsta from "../assets/svgs/insta";
import SvgTwitter from "../assets/svgs/twitter";
import SvgLinkedIn from "../assets/svgs/linkedIn";
import SvgFacebook from "../assets/svgs/facebook";
import { ReactNode } from "react";

export const IconButton: React.FC<{
	children: ReactNode;
	link?: string;
	noLine?: boolean;
}> = ({ children, link, noLine }) => {
	return (
		<a
			href={link}
			target="_blank"
			rel="noreferrer"
			className="flex items-center md:space-x-4 space-x-2">
			{children}
			{noLine ? null : <span className="text-lg">|</span>}
		</a>
	);
};

export function Icons() {
	return (
		<div className="flex items-center md:space-x-4 space-x-2">
			<IconButton link="https://www.facebook.com/bezosusuofficial">
				<SvgFacebook />
			</IconButton>

			<IconButton link="https://www.linkedin.com/company/bezosusu">
				<SvgLinkedIn />
			</IconButton>

			<IconButton link="https://twitter.com/mybezoapp">
				<SvgTwitter />
			</IconButton>

			<IconButton noLine link="https://www.instagram.com/mybezoapp/">
				<SvgInsta />
			</IconButton>

			{/* <IconButton link="">
				<SvgApple />
			</IconButton>
			<IconButton noLine link="">
				<SvgGoogle />
			</IconButton> */}
		</div>
	);
}
